import React from "react";
import Team1 from "../../images/img.png";
import Team2 from "../../images/img1.png";
import Team3 from "../../images/img2.png";

export default function Section5() {
  return (
    <div>
      <section
        class="sm:py-20 py-16 px-2 sm:px-0 bg-blue-50 overflow-hidden"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-4 mx-auto">
          <h2 class="sm:mb-20 mb-10 text-4xl md:text-6xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none text-blue-800">
            The brains behind TerraNova
          </h2>
          <div class="max-w-5xl mx-auto">
            <div class="flex flex-wrap -m-3">
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto">
                  <div class="sm:mb-6 mb-3 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Team3}
                      alt=""
                    />
                  </div>
                  <h3 class="mb-1 text-lg font-bold font-heading leading-snug text-gray-900 text-center">
                    Guha Jayaram
                  </h3>
                  <p class="font-medium text-gray-600 text-center">
                    Managing Director
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto">
                  <div class="mb-6 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Team1}
                      alt=""
                    />
                  </div>
                  <h3 class="mb-1 text-lg font-bold font-heading leading-snug text-gray-900 text-center">
                    Divye Kohil
                  </h3>
                  <p class="font-medium text-gray-600 text-center">Director</p>
                </div>
              </div>
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto">
                  <div class="mb-6 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Team2}
                      alt=""
                    />
                  </div>
                  <h3 class="mb-1 text-lg font-bold font-heading leading-snug text-gray-900 text-center">
                    Suhas Krishnaswamy
                  </h3>
                  <p class="font-medium text-gray-600 text-center">
                    Vice President
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
