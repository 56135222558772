import React from "react";
import Team1 from "../../images/img.png";
import Team2 from "../../images/img1.png";
import Team3 from "../../images/img2.png";
import Image from "../../images/about.webp";
import Ban from "../../images/ban.jpg";
import Image1 from "../../images/Picture10.jpg";
import Image2 from "../../images/Picture11.jpg";
import Image3 from "../../images/Picture12.jpg";
import Image4 from "../../images/Picture13.jpg";

import Img1 from "../../images/Picture2.jpg";
import Img2 from "../../images/Picture4.jpg";
import Img3 from "../../images/Picture5.jpg";
import Img4 from "../../images/Picture6.jpg";
import Img5 from "../../images/Picture7.jpg";
import Img6 from "../../images/Picture8.jpg";
import Img7 from "../../images/Picture9.jpg";
import Ramaswamy from '../../images/3.png'
import SAINATHAN from '../../images/1.png'
import Sak from '../../images/2.png'


import { VscActivateBreakpoints } from "react-icons/vsc";

export default function Aboutus() {
  return (
    <div>
      <section
        class="py-10 bg-gray-100 overflow-hidden"n
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container mx-auto px-4">
          <div
            class="py-16 px-8 md:px-16 rounded-3xl"
            style={{
              backgroundImage: `url(${Ban})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              // height: "63vh",
              backgroundRepeat: "no-repeat",
            }}
          >
            <h2 class="font-heading mb-6 text-4xl md:text-4xl text-white font-medium md:max-w-3xl">
              Company set up by E-waste management professionals with decades of
              experience in this industry
            </h2>
            <p class="mb-14 text-xl text-gray-200 font-semibold md:max-w-4xl">
              The Core team comprises of professionals each with 15-20+ Years
              Experience in E-waste and ITAD Industry. We shall also be hiring
              employees across the country to handle Logistics and On-Site
              operations. Currently we have started our operations with 30+
              employees and we shall further expand. We shall handle logistics,
              and Onsite Activities PAN India.
              <br /> We are offering services in the following.
            </p>
            <div class="flex flex-wrap -m-2">
              <div class="w-auto p-2">
                <div class="flex flex-wrap px-3 py-2 bg-white rounded-full">
                  <div class="w-auto mr-2 pt-1">
                    <svg
                      width="12"
                      height="12"
                      viewbox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                        fill="#3B82F6"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex-1">
                    <p class="text-md text-gray-900 font-bold">
                      E waste Recycling of All Categories like IT, Mobiles,
                      Appliances, Telecommunication equipment etc.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-auto p-2">
                <div class="flex flex-wrap px-3 py-2 bg-white rounded-full">
                  <div class="w-auto mr-2 pt-1">
                    <svg
                      width="12"
                      height="12"
                      viewbox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                        fill="#3B82F6"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex-1">
                    <p class="text-md text-gray-900 font-bold">
                      ITAD, ARS, Data Destruction (Both On-Site & Offsite), Data
                      Centers Commissioning, De-Commissioning.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-auto p-2">
                <div class="flex flex-wrap px-3 py-2 bg-white rounded-full">
                  <div class="w-auto mr-2 pt-1">
                    <svg
                      width="12"
                      height="12"
                      viewbox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                        fill="#3B82F6"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex-1">
                    <p class="text-md text-gray-900 font-bold">
                      {" "}
                      Extended Producer Responsibility (EPR)
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-auto p-2">
                <div class="flex flex-wrap px-3 py-2 bg-white rounded-full">
                  <div class="w-auto mr-2 pt-1">
                    <svg
                      width="12"
                      height="12"
                      viewbox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                        fill="#3B82F6"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex-1">
                    <p class="text-md text-gray-900 font-bold">
                      Government Liasioning Services, Reporting etc
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-auto p-2">
                <div class="flex flex-wrap px-3 py-2 bg-white rounded-full">
                  <div class="w-auto mr-2 pt-1">
                    <svg
                      width="12"
                      height="12"
                      viewbox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
                        fill="#3B82F6"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex-1">
                    <p class="text-md text-gray-900 font-bold">
                      Telecommunication Services like Installation,
                      De-Installation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------------------------------------------------------------- */}
      <section
        class="sm:py-10 py-10  px-1  sm:px-24 bg-white overflow-hidden"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap items-center -m-8">
            <div class="w-full md:w-1/2 p-8">
              <h2 class="mb-9 sm:pt-10 text-5xl md:text-5xl xl:text-10xl font-bold font-heading tracking-px-n leading-none text-blue-800">
                Get down to your business
              </h2>
              <p class="mb-6 text-lg text-gray-900 font-medium  md:max-w-lg">
                Established in 2022, Terranova Eco Management is a licensed and
                approved facility that has quickly become a premier destination
                for the responsible recycling of e-waste, batteries, precious
                metal recovery, metal refining, data destruction, and Telecom
                tower site operations. With a team of experienced individuals
                who have over 20 years of expertise in the field of e-waste
                management, we specialize in providing environmentally-sensitive
                recycling solutions.
              </p>

              <div class="hidden sm:block flex flex-wrap -m-2">
                <div class="w-auto p-2">
                  <img
                    src="flaro-assets/images/features/money-coins.png"
                    alt=""
                  />
                </div>
                <div class="hidden sm:block flex-1 p-2">
                  <p class="text-lg text-gray-900 font-medium  md:max-w-lg">
                    At Terranova Eco, we are driven by a strong commitment to
                    professional excellence, environmental stewardship, and
                    community engagement. Our purpose is to create a world
                    without waste and preserve our planet for future
                    generations. Sustainability is at the core of everything we
                    do, and we strive to make a positive impact through our
                    services and operations.
                  </p>
                </div>
                <div class="mb-11 pt-6 md:inline-block rounded-xl shadow-4xl">
                  <button
                    class="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-blue-800 hover:bg-yellow-500 transition ease-in-out duration-200"
                    type="button"
                  >
                    Learn How to Save More
                  </button>
                </div>
              </div>
            </div>
            <div class="hidden sm:block w-full md:w-1/2 p-8">
              <img
                class="transform hover:-translate-x-16 transition ease-in-out duration-1000"
                src={Image}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/* ----------------------------------------------------------------------------- */}
      <section
        class="sm:py-16 py-12 px-2 bg-blue-50 overflow-hidden"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-4 mx-auto">
          <h2 class="sm:mb-14 mb-10 text-4xl md:text-5xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none text-blue-800">
            The brains behind TerraNova
          </h2>
          <div class="max-w-6xl mx-auto">
            <div class="flex flex-wrap -m-3">
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto border rounded-md">
                  <div class="mb-6 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Team1}
                      alt=""
                    />
                  </div>
                  <div className="bg-blue-50 rounded-lg px-2">
                    <h3 class="mb-3 pt-3 text-lg font-bold font-heading leading-snug text-blue-800">
                      Divye Kohil (Director){" "}
                    </h3>
                    <p class="pb-8 font-medium text-gray-900 opacity-80 text-sm">
                      With over 20+ years of experience of E-Waste recycling in
                      India, Malaysia, Singapore and other asian countries{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto border rounded-md">
                  <div class="mb-6 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Team2}
                      alt=""
                    />
                  </div>
                  <div className="bg-blue-50 rounded-lg px-2">
                    <h3 class="mb-3 pt-3 text-lg font-bold font-heading leading-snug text-blue-800">
                      Suhas Krishnaswamy (Vice President){" "}
                    </h3>
                    <p class=" pb-3 font-medium text-gray-900 opacity-80 text-sm">
                      Passion towards e-waste management services, has managed
                      to work in multiple companies in top management roles{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto border rounded-md">
                  <div class="mb-6 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Team3}
                      alt=""
                    />
                  </div>
                  <div className="bg-blue-50 rounded-lg px-2">
                    <h3 class="mb-3 pt-3 text-lg font-bold font-heading leading-snug text-blue-800">
                      Guha Jayaram (Managing Director)
                    </h3>
                    <p class=" pb-8 font-medium text-gray-900 opacity-80 text-sm">
                      More than 12 years experience in E-Waste recycling, EPR
                      activities and refurbishing, Pan India{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="max-w-6xl mx-auto">
            <div class="flex flex-wrap -m-3">
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto border rounded-md">
                  <div class="mb-6 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Ramaswamy}
                      alt=""
                    />
                  </div>
                  <div className="bg-blue-50 rounded-lg px-2">
                    <h3 class="mb-3 pt-3 text-lg font-bold font-heading leading-snug text-blue-800">
                    RAMASWAMY ( Director Operations){" "}
                    </h3>
                    <p class="pb-8 font-medium text-gray-900 opacity-80 text-sm">
                    An entrepreneur in the E-waste industry since 2003, he excels in operations and infrastructure setup. At TES since 2007, he manages state-of-the-art recycling facilities, overseeing all operations.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto border rounded-md">
                  <div class="mb-6 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={SAINATHAN}
                      alt=""
                    />
                  </div>
                  <div className="bg-blue-50 rounded-lg px-2">
                    <h3 class="mb-3 pt-3 text-lg font-bold font-heading leading-snug text-blue-800">
                    SAINATHAN (Director Finance){" "}
                    </h3>
                    <p class=" pb-3 font-medium text-gray-900 opacity-80 text-sm">
                    Over 30 Years’ experience in Finance . Associated with E-waste Industry since 2008. Will be handling Finance, HR and Administration.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/3 p-3">
                <div class="max-w-max mx-auto border rounded-md">
                  <div class="mb-6 max-w-max overflow-hidden rounded-3xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Sak}
                      alt=""
                    />
                  </div>
                  <div className="bg-blue-50 rounded-lg px-2">
                    <h3 class="mb-3 pt-3 text-lg font-bold font-heading leading-snug text-blue-800">
                    SAKTHIVELAN ( VP Operations )
                    </h3>
                    <p class=" pb-8 font-medium text-gray-900 opacity-80 text-sm">
                    Since 2000, he has specialized in precious metal recovery and water treatment, leading the setup of Terranova's state-of-the-art facility. He will spearhead all expansion programs and operations
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---------------------------------------------------------------------------------- */}
      <section class="relative py-20 md:py-10 bg-white">
        <div class="relative container px-4 mx-auto">
          <div class="max-w-2xl lg:max-w-3xl mx-auto xl:max-w-7xl">
            <h2 class="sm:mb-14 mb-10 text-4xl md:text-5xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none text-blue-800">
              Office Area
            </h2>
            <div class="flex flex-wrap -mx-4 -mb-12">
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block mb-4 w-full h-72 object-cover rounded-xl"
                    src={Image1}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block mb-4 w-full h-72 object-cover rounded-xl"
                    src={Image2}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block mb-4 w-full h-72 object-cover rounded-xl"
                    src={Image3}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block mb-4 w-full h-72 object-cover rounded-xl"
                    src={Image4}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ------------------------------------------------------------------------------------ */}
      {/* <section class="relative sm:pt-24 pb-14 pt-14 sm:pb-28 sm:px-24 px-2 bg-blue-800 overflow-hidden" style={{fontFamily:'Red Hat Display'}} >
  <img class="absolute top-0 left-1/2 transform -translate-x-1/2" src="flaro-assets/images/numbers/gradient2.svg" alt=""/>
  <div class="relative z-10 container px-4 mx-auto">
    <div class="mb-16 border-b border-white border-opacity-10">
      <h2 class="sm:pb-24 pb-14 text-4xl md:text-6xl xl:text-10xl text-white font-bold tracking-px-n leading-none md:max-w-3xl">Join the tribe &amp; build your startup successful</h2>
    </div>
    <div class="flex flex-wrap -m-8">
      <div class="w-full md:w-1/3 p-8">
        <p class="mb-6 text-gray-200 font-semibold leading-normal">Satisfied Clients</p>
        <h3 class="mb-4 text-6xl md:text-7xl xl:text-10xl text-white font-bold tracking-px-n leading-none">84,900</h3>
        <p class="text-gray-300 font-medium leading-relaxed md:w-56">Flaro has garnered more than 15k+ users.</p>
      </div>
      <div class="w-full md:w-1/3 p-8">
        <p class="mb-6 text-gray-200 font-semibold leading-normal">Downloads</p>
        <h3 class="mb-4 text-6xl md:text-7xl xl:text-10xl text-white font-bold tracking-px-n leading-none">3.2M+</h3>
        <p class="text-gray-300 font-medium leading-relaxed md:w-56">Flaro has garnered more than 15k+ users.</p>
      </div>
      <div class="w-full md:w-1/3 p-8">
        <p class="mb-6 text-gray-200 font-semibold leading-normal">Customer Reviews</p>
        <h3 class="mb-4 text-6xl md:text-7xl xl:text-10xl text-white font-bold tracking-px-n leading-none">21,394</h3>
        <p class="text-gray-300 font-medium leading-relaxed md:w-56">Flaro has garnered more than 15k+ users.</p>
      </div>
    </div>
  </div>
</section> */}
      {/* ------------------------------------------------------------ */}
      <section
        class="relative sm:pt-14 sm:pb-12 sm:px-16 bg-white"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="relative z-10 container px-4 mx-auto">
          <div class="md:max-w-9xl mx-auto">
            <div class="flex flex-wrap -m-5">
              <div class="w-full md:w-1/2 p-5">
                <div class="bg-blue-50 bg-opacity-90 border border-blueGray-100 rounded-4xl shadow-9xl">
                  <div class="">
                    <div class="py-4 px-9">
                      <h3 class=" text-3xl text-blue-800 flex justify-center font-bold leading-snug">
                        Mission
                      </h3>
                    </div>
                  </div>
                  <div class="pt-5 pb-5 sm:px-16 px-4">
                    <ol class="mb-5 list-disc  text-gray-900">
                      <li class="mb-4 flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          Our mission at Terranova Eco Management is to protect
                          the environment and promote sustainable living by
                          responsibly managing and recycling electronic waste.
                        </p>
                      </li>
                      <li class="mb-4 flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          Leveraging cutting-edge technology and synchronized
                          processes, we provide comprehensive waste management
                          solutions that are not only cost-effective but also
                          environmentally sustainable.
                        </p>
                      </li>
                      <li class="mb-4 flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          We are dedicated to maintaining intellectual property
                          integrity and complying with legal regulations,
                          ensuring that we deliver high-quality services that
                          meet the needs of our clients.
                        </p>
                      </li>
                      <li class="mb-4 flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          We are committed to providing safe solutions for the
                          disposal of e-waste while creating economic
                          opportunities and promoting digital inclusion.
                        </p>
                      </li>
                      <li class="flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          Our goal is to reduce the carbon footprint of
                          electronic waste, preserve natural resources, minimize
                          waste generation, and protect our planet for future
                          generations.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              {/* -------------- */}
              <div class="w-full md:w-1/2 p-5">
                <div class="bg-blue-50 bg-opacity-90 border border-blueGray-100 rounded-4xl shadow-9xl">
                  <div class="">
                    <div class="py-4 px-9">
                      <h3 class=" text-3xl text-blue-800 flex justify-center font-bold leading-snug">
                        Vision
                      </h3>
                    </div>
                  </div>
                  <div class="pt-5 pb-5 sm:px-16 px-4">
                    <ol class="mb-5 list-disc  text-gray-900">
                      <li class="mb-4 flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          At Terranova Eco Management Private Limited, our
                          vision is to be a global leader in Electronic Waste
                          Management, ITAD, and metal recovery.{" "}
                        </p>
                      </li>
                      {/* <li class="mb-4 flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          {" "}
                          We aim to be recognized for our commitment to
                          responsible, sustainable practices and industry best
                          standards.
                        </p>
                      </li> */}
                      <li class="mb-4 flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          Our aspiration is to create a world where electronic
                          waste is no longer a threat to the environment but an
                          opportunity for growth and development.
                        </p>
                      </li>
                      <li class="mb-4 flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          We continuously innovate and improve our processes to
                          become a role model for the industry, fostering a
                          culture of sustainability and responsibility.
                        </p>
                      </li>
                      <li class="flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          Together, we will work towards a greener future for
                          all, where electronic waste is effectively managed,
                          valuable resources are conserved, and our planet
                          thrives.
                        </p>
                      </li>
                      <li class="flex items-center">
                        <div className=" text-blue-800 px-2">
                          {" "}
                          <VscActivateBreakpoints />
                        </div>

                        <p class="text-lg font-semibold leading-normal">
                          {" "}
                          At Terranova Eco, we are proud to be leading the way
                          in environmentally-conscious recycling and waste
                          management. Join us on our journey towards a more
                          sustainable world.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section class="sm:pt-20 sm:pb-28 pt-14 pb-14  bg-white overflow-hidden" style={{fontFamily:'Red Hat Display'}}>
  <div class="container px-4 mx-auto">
    <div class="md:max-w-2xl">
      <h2 class="mb-9 text-3xl md:text-6xl xl:text-10xl text-blue-800 font-bold tracking-px-n leading-none ">Take full control of design &amp; development</h2>
      <p class="sm:mb-20 mb-10 text-gray-900 font-medium text-opacity-70">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.</p>
    </div>
    <div class="flex flex-wrap -m-3">
      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-blue-50 text-lg  rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
              <svg width="28" height="28" viewbox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.66669 5.83329C4.66669 5.18896 5.18902 4.66663 5.83335 4.66663H22.1667C22.811 4.66663 23.3334 5.18896 23.3334 5.83329V8.16663C23.3334 8.81096 22.811 9.33329 22.1667 9.33329H5.83335C5.18902 9.33329 4.66669 8.81096 4.66669 8.16663V5.83329Z" stroke="#6366F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M4.66669 15.1666C4.66669 14.5223 5.18902 14 5.83335 14H12.8334C13.4777 14 14 14.5223 14 15.1666V22.1666C14 22.811 13.4777 23.3333 12.8334 23.3333H5.83335C5.18902 23.3333 4.66669 22.811 4.66669 22.1666V15.1666Z" stroke="#6366F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M18.6667 15.1666C18.6667 14.5223 19.189 14 19.8334 14H22.1667C22.811 14 23.3334 14.5223 23.3334 15.1666V22.1666C23.3334 22.811 22.811 23.3333 22.1667 23.3333H19.8334C19.189 23.3333 18.6667 22.811 18.6667 22.1666V15.1666Z" stroke="#6366F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-bold text-blue-800">Vision</h3>
              <p class="text-gray-900 font-medium text-opacity-70 text-base ">At Terranova Eco Management Private Limited, our vision is to be a global leader in Electronic Waste Management, ITAD, and metal recovery. We aim to be recognized for our commitment to responsible, sustainable practices and industry best standards. Our aspiration is to create a world where electronic waste is no longer a threat to the environment but an opportunity for growth and development. <br/>We continuously innovate and improve our processes to become a role model for the industry, fostering a culture of sustainability and responsibility. Together, we will work towards a greener future for all, where electronic waste is effectively managed, valuable resources are conserved, and our planet thrives.</p>
              <p class="text-gray-900 font-medium text-opacity-70 text-base ">At Terranova Eco, we are proud to be leading the way in environmentally-conscious recycling and waste management. Join us on our journey towards a more sustainable world.</p>

            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-3 max-w-6xl">
        <div class="p-7 bg-blue-50 text-blue-800 font-bold text-lg  rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
              <svg width="28" height="28" viewbox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5 11.6667H24.5M8.16667 17.5H9.33333M14 17.5H15.1667M7 22.1667H21C22.933 22.1667 24.5 20.5997 24.5 18.6667V9.33337C24.5 7.40038 22.933 5.83337 21 5.83337H7C5.067 5.83337 3.5 7.40038 3.5 9.33337V18.6667C3.5 20.5997 5.067 22.1667 7 22.1667Z" stroke="#6366F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-bold">Mission</h3>
              <p class="text-gray-900 font-medium text-opacity-70 text-base">Our mission at Terranova Eco Management is to protect the environment and promote sustainable living by responsibly managing and recycling electronic waste. Leveraging cutting-edge technology and synchronized processes, we provide comprehensive waste management solutions that are not only cost-effective but also environmentally sustainable. We are dedicated to maintaining intellectual property integrity and complying with legal regulations, ensuring that we deliver high-quality services that meet the needs of our clients.</p>
              <p class="text-gray-900 font-medium text-opacity-70 text-base">We are committed to providing safe solutions for the disposal of e-waste while creating economic opportunities and promoting digital inclusion. <br/>Our goal is to reduce the carbon footprint of electronic waste, preserve natural resources, minimize waste generation, and protect our planet for future generations.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}

      {/* -------------------------------------------------------------------------------------------- */}

      <section>
        <div
          class="container px-4 mx-auto pb-10 bg-white"
          style={{ fontFamily: "Red Hat Display" }}
        >
          <div class="md:mb-6 lg:mb-10 flex flex-wrap items-end justify-between">
            <div class="mb-4 md:mb-0">
              <h2 class=" text-3xl md:text-5xl xl:text-10xl text-blue-800 font-bold tracking-px-n leading-none ">
                Glimpses Of ITAD Facility @ Bangalore
              </h2>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/3 p-3">
              <img class="w-full h-80  rounded-lg" src={Img1} alt="" />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img class="w-full h-80  rounded-lg" src={Img2} alt="" />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img class="w-full h-80 rounded-lg" src={Img6} alt="" />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-80  rounded-lg object-top"
                src={Img4}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img class="w-full h-80 rounded-lg" src={Img5} alt="" />
            </div>
            {/* <div class="w-full md:w-1/3 p-3">
              <img class="w-full h-80  rounded-lg" src={Img3} alt="" />
            </div> */}
            <div class="w-full md:w-1/3 p-3">
              <img class="w-full h-80  rounded-lg" src={Img7} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
